<template>
  <div class="pain-spot">
    <h3>企业数字化转型现状  ·  situation</h3>
    <p>当前大多数企业在数字化转型方面存在以下问题</p>
    <ul>
      <li>
        <img src="/static/img/synergy-img1.png"/>
        <div>
          <h3>系统建设缺少顶层规划</h3>
          <p>大多数企业对某些业务板块、某些部门的系统需求进行碎片化建设，缺少公司层面的顶层远期规划。</p>
        </div>
      </li>
      <li>
        <div style="padding-left:0">
          <h3>各业务系统割裂</h3>
          <p>系统建设的碎片化造成了各个业务系统的割裂。员工经常要反复切换于不同系统之间，给工作的推进造成极大的不便。</p>
        </div>
        <img src="/static/img/synergy-img2.png"/>
      </li>
      <li>
        <img src="/static/img/synergy-img3.png"/>
        <div>
          <h3>数据孤岛众多</h3>
          <p>系统之间的不互通造成了众多的数据孤岛。企业无法以全局视角总览数据全景，也很难进行高效可行的决策。</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<style lang="less" scoped>
.pain-spot{
  padding: 50px 0;
  color: #383838;
  >h3{
    text-transform: uppercase;
    font-size: 18px;
    color: #383838;
    font-weight: bold;
    text-align: center;
    padding: 0 10px;
  }
  >p{
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
    padding: 0 10px;
    margin-bottom: 40px;
  }
  >ul{
    padding: 0 20px;
    >li{
      display: flex;
      align-items: center;
      &:not(:last-child){
        margin-bottom: 40px;
      }
      >div{
        flex: 1;
        padding: 0 0 0 10px;
        box-sizing: border-box;
        color: #383838;
        >h3{
          font-size: 16px;
          font-weight: bold;
        }
        >p{
          font-size: 13px;
          font-weight: 300;
          line-height: 2;
          margin-top: 10px;
        }
      }
      >img{
        width: 157px;
        display: block;
      }
    }
  }
}
</style>