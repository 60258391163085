<template>
  <div class="component-box">
    <h3>解决方案构成  ·  component</h3>
    <div class="component-item" v-for="(item,index) in list" :key="index">
      <h3><span>0{{index+1}}</span>-{{item.name}}</h3>
      <p>{{item.remark}}</p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list:[
        {name:'协同办公',remark:'基于专属钉钉底座打造企业专属的数字化办公平台，基础功能涵盖：组织管理、即时通信、视频会议、群直播、任务日程、待办审批、智能考勤、企业邮箱、网盘等。'},
        {name:'智能门户',remark:'打造企业的移动办公门户，实现统一门户、统一认证、角色工作台等功能，方便员工安全、集中、高效地处理工作。'},
        {name:'业务融合',remark:'基于钉钉开放平台，实现统一组织、统一流程、统一消息、应用中心等功能，满足企业内部各业务系统间的业务集成需求。'},
        {name:'数智洞察',remark:'整合10大主题数据、40+专属数据指标，为企业提供数字化运营数据指标管理、分析、展示和分发，更高效的辅助企业数据分析及决策。'},
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.component-box{
  padding: 50px 15px;
  color: #383838;
  >h3{
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }
  .component-item{
    border: 1px solid #E2E2E2;
    padding: 20px;
    background-color: #fff;
    &:not(:last-child){
      margin-bottom: 10px;
    }
    &:nth-child(2n-1){
      background-color: #F6FBFF;
    }
    >h3{
      font-size: 17px;
      color: #383838;
      font-weight: bold;
      line-height: 1.5;
      >span{
        color: #E80B10;
      }
    }
    >p{
      font-size: 13px;
      color: #383838;
      line-height: 2;
      margin-top: 10px;
      text-align: justify;
    }
  }
}
</style>