<template>
  <div class="value-box">
    <h3>数智协同价值与优势  ·  VALUE</h3>
    <p>数智协同解决方案将为企业带来以下价值</p>
    <ul>
      <li v-for="(item,index) in list" :key="index">
        <img :src="item.img"/>
        <h3>{{item.title}}</h3>
        <p>{{item.desc}}</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data(){
    return {
      list:[
        {img:'/static/img/synergy-img10.jpg',title:'统一门户',desc:'将各类业务系统应用集成钉钉，打造企业移动统一门户，单点登陆让操作更简单、多业务流程待办处理更加高效。'},
        {img:'/static/img/synergy-img11.jpg',title:'统一集成',desc:'基于钉钉开放平台，让组织、流程、消息等快速接入，与各类业务系统集成，打造更高效流程。'},
        {img:'/static/img/synergy-img12.jpg',title:'统一协同',desc:'升级公文、日程、用印、流程、日程、会议等办公应用，打造更好的OA办公体验，支撑全员跨部门敏捷协作。'},
        {img:'/static/img/synergy-img13.jpg',title:'APP专属化',desc:'构建企业自己的移动办公专属APP，提升企业品牌形象与员工归属感，提效企业移动办公。'}
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.value-box{
  padding: 50px 0;
  color: #383838;
  background-color: #F6F6F8;
  >h3{
    text-transform: uppercase;
    font-size: 18px;
    color: #383838;
    font-weight: bold;
    text-align: center;
    padding: 0 10px;
  }
  >p{
    font-size: 14px;
    font-weight: 300;
    margin-top: 15px;
    margin-bottom: 20px;
    text-align: center;
    padding: 0 10px;
  }
  >ul{
    display: flex;
    align-items: stretch;
    margin: auto 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    >li{
      width: calc( 50% - 10px );
      margin: 18px 5px 0;
      padding: 12px;
      box-sizing: border-box;
      border: 1px solid #E8EAED;
      >img{
        width: 100%;
        display: block;
        margin: auto;
      }
      >h3{
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        color: #383838;
        padding: 20px 0 10px;
      }
      >p{
        font-size: 13px;
        color: #656464;
        line-height: 2;
        text-align: justify;
      }
    }
  }
}
</style>